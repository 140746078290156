<template>
  <portal>
    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-cog</v-icon>
          <h1 class="darkGrey--text">General Settings</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Complete the form with the correct information for your organization.<br>
              When you select a {{subdivision.label.toLowerCase()}}, an appropriate timezone will be<br>
              suggested to you that you can change if necessary.<br>
              You can also change your app plan below.
            </span>
          </v-tooltip>
        </span>

      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <v-form ref='generalSettingsForm'>
            <v-row>
              <v-col>

                <v-card>
                  <v-card-text class="px-10 py-4">

                    <v-row>
                      <v-col style="max-width: 250px; min-width: 250px;">

                        <v-row>
                          <v-col>
                            <v-menu offset-y open-on-hover>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar
                                  color="white"
                                  size="200"
                                  tile
                                  class="rounded-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                <img
                                v-if="client.logo"
                                alt="Avatar"
                                :src="client.avatarURI"
                                >
                                <v-icon
                                  v-else color="blue lighten-5" size="150">mdi-city-variant-outline</v-icon>
                                </v-avatar>
                              </template>
                              <v-list dense>
                                <v-list-item class="d-flex justify-center align-center">
                                  <span>
                                    <v-btn icon @click="openModal('image')">
                                      <v-icon>mdi-cloud-upload-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon :disabled="!client.logo" @click="openModal('imageDelete')">
                                      <v-icon color="important">mdi-delete</v-icon>
                                    </v-btn>
                                  </span>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col>

                        <v-row>
                          <v-col>
                            <span class="secondary--text text-uppercase font-weight-bold">
                              Organization Name
                            </span>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-text-field
                              outlined
                              label="Name"
                              v-model="client.name"
                              :rules="[v => !!v || 'Name is required' ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-4">
                          <v-col>
                            <span class="secondary--text text-uppercase font-weight-bold">
                              Location and Time Zone
                            </span>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="6">
                            <v-select
                              id="countrySelect"
                              label="Country"
                              outlined
                              :items="country.items"
                              v-model="client.country"
                              v-on:change="countrySelected"
                              required
                              :rules="[v => !!v || 'Country is required']"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              id="subdivisionSelect"
                              :label="subdivision.label"
                              outlined
                              :items="subdivision.items"
                              v-model="client.state"
                              v-on:change="subdivisionSelected"
                              :rules="[v => !!v || 'Political Subdivision is required' ]"
                              required
                              :disabled="this.subdivisionDisabled"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              outlined
                              label="City"
                              :rules="[v => !!v || 'City is required' ]"
                              v-model="client.city"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="5">
                            <v-select
                              :items="timeZones"
                              outlined
                              label="Time Zone"
                              v-model="client.timezone"
                              ref="timezoneSelect"
                              :rules="[v => !!v || 'Time Zone is required' ]"
                              :value="client.timezone"
                            ></v-select>
                          </v-col>
        <!--
                          <v-col cols="8" class="d-flex align-center">
                            <v-switch
                              inset
                              label="Observe daylight saving time"
                              color="secondary"
                              class="mt-0 pa-0"
                              hide-details
                            ></v-switch>
                          </v-col>
                        -->
                        </v-row>

                      </v-col>
                    </v-row>

                  </v-card-text>

                  <v-card-actions class="pa-12 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn depressed width="130" color="secondary" @click="submitForm()">
                      <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
                      Save
                    </v-btn>
                  </v-card-actions>

                </v-card>

              </v-col>
            </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row class="d-flex mt-10">
      <v-col class="d-flex">

        <v-card elevation="5" class="mx-4">
          <v-card-text class="d-flex flex-column pa-8">

            <span class="text-uppercase darkGrey--text text-h5 font-weight-bold text-center">
              First
            </span>

            <span class="text-uppercase secondary--text text-body-1 font-weight-black mb-6 text-center">
              Plan
            </span>

            <span class="darkGrey--text mb-6 text-center">
              <span class="text-h6 font-weight-medium">$</span>
              <span class="text-h5 font-weight-bold">2.75</span>
              <span>/MO*</span>
            </span>

            <v-btn
              class="mb-8"
              depressed
              text
              disabled
            >
              <span class="secondary--text">
                This is your plan
              </span>
            </v-btn>

            <v-divider class="mb-8"></v-divider>

            <p class="text-caption">
              <strong>50 duis</strong> bibendum libero
              <br><br>
              <strong>2 est</strong> venenatis sagittis morbi gravida
              <br><br>
                <strong>10 velit</strong> nulla quis consectetur dui placera
              <br><br>
              Aliquam nisl ex, elementum in commodo
              <br><br>
              Vivamus metus ante dignissim scelerisque
              euismod at, molestie eu arcu
              <br><br>
              Vivamus pretium erat non neque suscipit
              <br><br>
              Velit nulla quis consectetur dui placerat non
            </p>

          </v-card-text>
        </v-card>

        <v-card elevation="1" color="grey lighten-3" class="mx-4">
          <v-card-text class="d-flex flex-column pa-8">

            <span class="text-uppercase darkGrey--text text-h5 font-weight-bold text-center">
              Second
            </span>

            <span class="text-uppercase secondary--text text-body-1 font-weight-black mb-6 text-center">
              Plan
            </span>

            <span class="darkGrey--text mb-6 text-center">
              <span class="text-h6 font-weight-medium">$</span>
              <span class="text-h5 font-weight-bold">3.95</span>
              <span>/MO*</span>
            </span>

            <v-btn
              class="mb-8"
              depressed
              color="secondary"
            >
              Switch to this plan
            </v-btn>

            <v-divider class="mb-8"></v-divider>

            <p class="text-caption">
              <strong>150 duis</strong> bibendum libero
              <br><br>
              <strong>4 est</strong> venenatis sagittis morbi gravida
              <br><br>
              <strong>40 velit</strong> nulla quis consectetur dui placera
              <br><br>
              Aliquam nisl ex, elementum in commodo
              <br><br>
              Vivamus metus ante dignissim scelerisque
              euismod at, molestie eu arcu
              <br><br>
              Vivamus pretium erat non neque suscipit
              <br><br>
              Velit nulla quis consectetur dui placerat non
              <br><br>
              Interdum et malesuada fames ac ante
              ipsum primis in faucibus
            </p>

          </v-card-text>
        </v-card>

        <v-card elevation="1" color="grey lighten-3" class="mx-4">
          <v-card-text class="d-flex flex-column pa-8">

            <span class="text-uppercase darkGrey--text text-h5 font-weight-bold text-center">
              Third
            </span>

            <span class="text-uppercase secondary--text text-body-1 font-weight-black mb-6 text-center">
              Plan
            </span>

            <span class="darkGrey--text mb-6 text-center">
              <span class="text-h6 font-weight-medium">$</span>
              <span class="text-h5 font-weight-bold">5.95</span>
              <span>/MO*</span>
            </span>

            <v-btn
              class="mb-8"
              depressed
              color="secondary"
            >
              Switch to this plan
            </v-btn>

            <v-divider class="mb-8"></v-divider>

            <p class="text-caption">
              <strong>300 duis</strong> bibendum libero
              <br><br>
              <strong>8 est</strong> venenatis sagittis morbi gravida
              <br><br>
              <strong>100 velit</strong> nulla quis consectetur dui placera
              <br><br>
              Aliquam nisl ex, elementum in commodo
              <br><br>
              Vivamus metus ante dignissim scelerisque
              euismod at, molestie eu arcu
            </p>
            <v-btn>
            <v-icon>mdi-fingerprint</v-icon>
            </v-btn>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <general-settings-image :clientId="this.$store.state.client.activeClient.clientID" :name="client.name" v-model="modals.image"></general-settings-image>
    <general-settings-image-delete :clientId="this.$store.state.client.activeClient.clientID" :name="client.name" v-model="modals.imageDelete" @reload="getDataFromApi"></general-settings-image-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import GeneralSettingsImage from '@/components/GeneralSettings/GeneralSettingsImage'
import GeneralSettingsImageDelete from '@/components/GeneralSettings/GeneralSettingsImageDelete'
import axios from 'axios'
import localities from '@/assets/general-settings/localities.json'

export default Vue.extend({
  name: 'GeneralSettings',
  components: {
    Portal,
    GeneralSettingsImage,
    GeneralSettingsImageDelete
  },
  data: () => ({
    modals: {
      image: false,
      imageDelete: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'General Settings',
        disabled: true,
        href: ''
      }
    ],
    timeZones: [],
    selectedTimeZone: null,
    subdivisionsForSelect: [],
    country: {
      selected: null,
      items: localities.countries
    },
    subdivision: {
      selected: null,
      items: [],
      label: 'State'
    },
    subdivisionDisabled: true,
    validGeneralSettingsForm: false,
    client: {
      name: '',
      descript: '',
      country: '',
      state: '',
      city: '',
      status: '',
      logo: '',
      timezone: '',
      plan: '',
      avatarURI: ''
    },
    clientToSave: null,
    saving: false
  }),
  watch: {
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.getDataFromApi()
        }
      }
    })
    // Would probably be better to use a model to retreive data and then get when mounted.
    // Compare to Boards, although the page does not function the same way and updates must be called frequently.
    this.getDataFromApi()
    this.fetchTimeZones()
  },
  beforeDestroy () {
    this.unsubscribe()
  },
  computed: {
    getSelectedSubdivisonObject () {
      return !this.client.state || this.subdivision.items.filter(eachSubdivision => {
        return eachSubdivision.value.indexOf(this.client.state) > -1
      })
    }
  },
  methods: {
    openModal (item) {
      this.modals[item] = true
    },
    getDataFromApi () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/general-settings/' + this.$store.state.client.activeClient.clientID)
        .then((response) => {
          this.client = response.data
          this.countrySelected()
          this.subdivisionSelected()
          this.client.avatarURI = process.env.VUE_APP_IMAGES_S3_BUCKET + '/' + process.env.VUE_APP_IMAGES_S3_FOLDER_ORGANIZATION_AVATARS + '/' + this.client.logo
        }).catch(function (error) {
          console.log(error)
        })
    },
    fetchTimeZones () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/general-settings/time-zones')
        .then((response) => {
          this.timeZones = response.data
        })
    },
    countrySelected () {
      this.subdivision.label = localities.subdivisions[this.client.country].subdivisionName
      this.subdivision.items = localities.subdivisions[this.client.country].subdivisions
      this.subdivisionDisabled = false
    },
    subdivisionSelected () {
      if (!this.client.timezone) {
        let zone
        for (zone in this.timeZones) {
          if (this.timeZones[zone].value.includes(this.getSelectedSubdivisonObject[0].bestFitTimeZone)) {
            this.client.timezone = this.timeZones[zone]
            return
          }
          this.selectedTimeZone = null
        }
      }
    },
    submitForm () {
      if (this.$refs.generalSettingsForm.validate()) {
        this.saving = true
        delete this.client.id
        delete this.client.avatarURI
        axios.put(process.env.VUE_APP_BACKEND_URL + '/api/general-settings/' + this.$store.state.client.activeClient.clientID, this.client)
          .then((response) => {
            this.saving = false
            this.getDataFromApi()
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
  }
})
</script>
