<template>

  <v-dialog
    width="600"
    height="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span>Upload an image for</span>
        </span>
        <span><span class="font-weight-black">{{ name }}</span></span>
      </v-card-title>
      <template>
        <v-file-input
          :rules="imageRules"
          accept="image/png, image/jpeg, image/bmp"
          prepend-icon="mdi-camera"
          label="Client Avatar"
          v-model="avatarFile"
        ></v-file-input>
      </template>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false; avatarFile=null"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text class="text-center">

        <v-row>
          <v-col>
            &nbsp;
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed @click="upload">
          <span v-if="!avatarFile">Close</span>
          <span v-else>
            <v-icon left small class="green--text">mdi-upload</v-icon>Upload Avatar
            </span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'GeneralSettingsImage',
  data: () => ({
    avatarFile: null,
    imageRules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      value => !value || (value.type === 'image/gif' || value.type === 'image/jpeg' || value.type === 'image/png') || 'File must be an image'
    ],
    progress: 0,
    message: null
  }),
  props: {
    name,
    clientId: {
      required: false,
      type: Number
    },
    value: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    upload () {
      if (this.avatarFile === null) {
        this.show = false
        return false
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  created () {
    //
  }
})
</script>
